@charset "utf-8";



body {
  font-size: 13px;
  font-weight: 400;
}



//== Global Radius
// ----------------------------------------------------------------------------
//## Common styles for component corners

//** Global radius sizes
$border-radius-base: 0px;
$border-radius-small: 3px;
$border-radius-large: 6px;

$font-family-arial: Arial, sans-serif;

$bar-graph-width: 256px;
$bar-graph-font-size: 10px;
$bar-graph-text-color: $medium-grey-10;


$splitbar-border: $panel-border;
$splitbar-width: 0px;

$alert-background: $light-orange;
$alert-text: $dark-orange;

$resource-text-color: $medium-grey-10;
$resource-label-size: 1.2em;
$resource-value-size: 1.6em;

$separator-vertical-spacing: 10px;


$outline-focus: 1px dotted #212121; // FF and Edge focus style
$outline-webkit-focus: 5px auto -webkit-focus-ring-color; // Chrome focus style


*:focus, a:focus, input:focus {
  outline: 0;
}

.user-tabbing {
  a:focus {
    outline: $outline-focus;
    outline: $outline-webkit-focus;
  }
  button:not(.close):focus {
    outline: $outline-focus;
    outline: $outline-webkit-focus;
  }
  *:not(.select) > select:focus {
    outline: $outline-focus;
    outline: $outline-webkit-focus;
  }
}

/* APP HEADER */

.branding {
  a:hover {
    text-decoration: none;
  }
}

/* APP BODY */

.vui-vertical-panel.k-splitter .vui-panel.top-level > .panel-inner {
  background-color: $vui-background-color;
  color: $vui-text-color;
}

.vui-vertical-panel.k-splitter .vui-panel.top-level > .vui-popup {
  background-color: $light-grey-20;
}

// Menu
$menu-background-color: $tree-item-hover;

.k-menu > .k-state-hover {
  background-color: $menu-background-color;
  color: inherit;
}

.k-list .k-state-border-right,
.k-list .k-state-selected,
.k-menu .k-state-border-right,
.k-menu .k-state-selected {
  background-color: $clr-row-selection-color;
  color: $vui-text-color;
}

.k-list > .k-state-hover .k-state-border-right,
.k-menu > .k-state-hover .k-state-border-right {
  background-color: $clr-row-hover-color;
  color: $vui-text-color;
}

.k-menu .k-item > .k-state-border-left,
.k-menu .k-item > .k-state-border-right {
  background-color: $grey-menu-background;
  color: $menu-font-color;
}

.k-list .k-menu-group > .k-state-hover > .k-link,
.k-menu .k-menu-group > .k-state-hover > .k-link {
  background-color: $clr-row-hover-color;
  color: $vui-text-color;
}

/* OBJECT NAVIGATOR */
.vui-vertical-panel.k-splitter .vui-panel .panel-inner .panel-content,
.vui-vertical-panel.k-splitter .vui-panel .vui-popup .panel-content {
  background-color: $vui-background-color;
}

$max-width: 920px;
$center-content-padding: 20px;
$nav-tab-padding: 20px;

.content-no-tab, .outer-tab-content {
  border-radius: $border-radius-base;
  padding-right: $center-content-padding;
  padding-left: $center-content-padding;
}

center-panel .fill-parent .fill-parent .fill-parent,
center-panel .outer-tab-content > .fill-parent {
  max-width: $max-width;

  [vx-view] {
    > div {
      max-width: $max-width - ($center-content-padding * 2);
    }
  }
}

#center-pane .outer-tab-content {
  overflow-y: scroll;
  height: calc(100% - 110px);
}

#center-pane.vui-panel {
  .center-pane-container {
    height: 100%;
    background-color: $center-pane-color;

    .content-no-tab {
      overflow: visible;
      max-width: $max-width - ($center-content-padding * 2) - ($nav-tab-padding * 2);
    }

    .panel-inner {
      max-width: $max-width;
    }
  }
}

.vui-panel .panel-inner {
  background: $vui-background-color;
  border-radius: 0px;
}

/* TITLE BAR */
.titlebar {
  background-image: none;
  border-bottom: none;
}

.titlebar {
  padding-top: 18px;
  padding-left: 18px;
}

.titlebar-left-icons {
  font-size: 19px;
}

.titlebar-text {
  font-size: 14px;
  padding-top: 7px;
}

left-panel, center-panel, right-panel, bottom-panel {
  border: none;
}

center-panel, #center-pane {
  &.vui-panel .panel-inner {
    background-color: $center-pane-color;
  }
  .outer-tab-content {
    background-color: $center-pane-color;
  }
  .nav.nav-tabs {
    background-color: $center-pane-color;
  }
}

#right-panel .panel-content {
  background-color: $vui-background-color;
}

left-panel .vui-panel .panel-inner .panel-content,
center-panel .vui-panel .panel-inner .panel-content,
right-panel .vui-panel .panel-inner .panel-content {
  top: 35px;
}

#center-pane .outer-tab-content {
  top: 82px;
}

#center-pane .outer-tab-content-no-title {
  top: 37px;
}

.nav-top-block {
  background-color: inherit;
}

.panel-content.nav-bottom-block {
  background-color: inherit !important; // !important was used in vui-bootstrap
}

/* SPLITTER */
.k-splitbar, .k-splitbar.k-splitbar-horizontal-hover, .k-splitbar.k-splitbar-vertical-hover {
  background-color: $vui-background-color;
}

.k-splitbar-horizontal .k-collapse-prev,
.k-splitbar-horizontal .k-collapse-next,
.k-splitbar-horizontal .k-expand-next,
.k-splitbar-horizontal .k-expand-prev,
.k-splitbar-horizontal-hover,
.k-splitbar-horizontal .k-resize-handle,
.k-splitbar-vertical .k-collapse-prev,
.k-splitbar-vertical .k-collapse-next,
.k-splitbar-vertical .k-expand-next,
.k-splitbar-vertical .k-expand-prev,
.k-splitbar-vertical-hover,
.k-splitbar-vertical .k-resize-handle {
  background-position: 0;
}

.k-splitter .k-ghost-splitbar-horizontal,
.k-splitter .k-splitbar-horizontal {
  border-left: $splitbar-border;
  border-right: transparent;
  background: transparent;
}

.k-splitter .k-ghost-splitbar-vertical,
.k-splitter .k-splitbar-vertical {
  border-top: $splitbar-border;
  border-bottom: transparent;
  background: transparent;
}

div.k-icon.k-collapse-next,
div.k-icon.k-resize-handle {
  display: none;
}

.vui-vertical-panel.k-splitter .vui-panel .panel-inner .panel-content,
.vui-vertical-panel.k-splitter .vui-panel .vui-popup .panel-content {
  overflow: auto;
}

.k-splitter .k-ghost-splitbar-horizontal, .k-splitter .k-splitbar-horizontal {
  width: $splitbar-width;
}

.k-splitter .k-ghost-splitbar-vertical, .k-splitter .k-splitbar-vertical {
  height: $splitbar-width;
}

/* TREE VIEW */
div[vui-tree-view] .k-state-selected {
  background-color: $tree-item-selected;
  color: $menu-font-color;
}

div[vui-tree-view] .k-state-selected.k-state-focused {
  outline: none;
}

div[vui-tree-view] .k-state-focused {
  outline: none;
}

div[vui-tree-view] .k-in.k-state-hover {
  background-color: $clr-row-hover-color;
  color: $vui-text-color;
}

li[role=treeitem] span {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* HOME PAGE */
.controlcenter-category {
  background: none;
  font-size: 18px;
  color: $clr-title-color;
  border-bottom: 1px solid $light-grey-40;
}

// General navigation concepts
.nav {
  a.nav-link:hover, a.nav-link:active {
    color: $medium-grey-10;
  }

  &.nav-tabs {
    // there is no fault tolerance for tabs the fall off.
    // tabs shall not be designed as a trivial UL>LI markup system
    // very flawed in concept
    display: flex; // UX needs to come up with an RWD tab system
    overflow-x: hidden;
    white-space: nowrap;
    // safety for boundary constraints - CSS limits, need widget intelligence

    padding-top: 2px;
    background-color: inherit;
    width: inherit;
    //display: block;
    height: 26px;
    font-size: 14px;
    box-shadow: none;

    > LI {

      > a {
        height: 9px;
        border: none;

        //box-shadow: inset 0 -2px 0px transparent; // unbind transition FX
        border-bottom: 3px solid transparent;
        transition: border-bottom 0.25s;

        // emphasize other candidate nav items
        &:not(.active):hover {
          border: 0;
          height: 9px;
          background: transparent;
          border-bottom: 3px solid $clr-actionblue;

          // box-shadow: inset 0 -@tab1-highlight-border-width 0px @tab1-shape-hover-border;
          transition: border-bottom 0.25s;
          //transition: box-shadow 0.25s;

        }

      }

      // ~~~~~~~ state modifiers ~~~~~~~~~~~
      &.active {
        > a {
          background-color: inherit;
          border: 0px;
          border-bottom: 3px solid $clr-actionblue;
          border-radius: 0;

          &:hover {
            border: 0px;
            height: 9px;
            background: transparent;
            border-bottom: 3px solid $clr-actionblue;
          }
        }
      }

    }

  }

}

.nav-tabs {
  border-bottom: 1px solid $light-grey-40;
}

/* PROGRESS BAR */
.resource-meter .normal .progress .progress-bar {
  background-image: none;
  background-color: $resource-bar-color;
}

.resource-meter .progress {
  background-image: none;
  background-color: $light-grey-20;
  border: 0px;
  border-radius: 0px;
}

.progress {
  -webkit-box-shadow: none;
  box-shadow: none;
  width: auto;
}

/* SUMMARY VIEW */
.summary h6.summary-name-label {
  font-weight: 200;
}

/* DIALOGS AND POPUPS */

.vui-popup {
  border-radius: 0px;
  border: 1px solid $medium-grey;
  box-shadow: 0 0 10px rgba(88, 104, 119, 0.3);
}

.vui-popup.vui-dialog .titlebar, .vui-popup .titlebar {
  background: $vui-background-color;
  padding-top: 5px;
}

.vui-popup.vui-dialog .titlebar-text, .vui-popup .titlebar-text {
  font-size: 18px;
  color: $dark-grey;
  text-overflow: ellipsis;
}

.vui-popup.vui-dialog .dialog-footer {
  border-top: 0px;
  background: none;
}

.summary-meters-block {
  min-width: $bar-graph-width;

  li.resource-meter {
    line-height: 16px;

    span {
      color: $bar-graph-text-color;
      font-family: $font-family-arial;
      font-size: $bar-graph-font-size;
    }
  }
}

.summary-icons-block {
  color: $resource-text-color;

  .resource-label {
    font-size: $resource-label-size;
  }
  .resource-value {
    font-size: $resource-value-size;
  }

  table tr td:first-child {
    padding-right: 15px;
  }
}

div.panel-content.nav-bottom-block.objNavSeparator {
  background-color: inherit !important;
}

/* PORTLET */
.vui-portlet {
  border: $portlet-border;
  border-radius: 0px;
}

.vui-portlet .portlet-titlebar {
  background: $vui-background-color;
  color: $vui-text-color;
  border-bottom: none;
  border-radius: 0px;

  .titlebar-text {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.22;
  }
}

.portlet-content .vui-stack-view .stack-view-table {
  border: none !important;
}

.vui-portlet .portlet-footer {
  background-color: $vui-background-color;
  color: $vui-text-color;
  border-top: 0px;
}

// Stack view

$stack-view-label-background: $center-pane-color;

.portlet-content {
  padding: 10px;

  .vui-stack-view {
    border: 1px solid $light-grey-30;
    border-radius: $border-radius-small;
    overflow-x: scroll;
  }
}

.vui-stack-view .stack-view-table .stack-view-child .stack-view-cell:first-child,
.vui-stack-view .stack-view-table .stack-view-child-i .stack-view-cell:first-child,
.vui-stack-view .stack-view-table .stack-view-parent .stack-view-cell:first-child,
.vui-stack-view .stack-view-table .stack-view-parent-i .stack-view-cell:first-child,
.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell:first-child,
.vui-stack-view .stack-view-table .stack-view-row-i .stack-view-cell:first-child {
  background-color: $stack-view-label-background;
  border-right: 1px solid $light-grey-30;
}

.vui-stack-view .stack-view-table .stack-view-parent .stack-view-cell:first-child:before,
.vui-stack-view .stack-view-table .stack-view-parent-i .stack-view-cell:first-child:before {
  margin-top: 6px;
}

.vui-stack-view .stack-view-table .stack-view-child .stack-view-cell,
.vui-stack-view .stack-view-table .stack-view-child-i .stack-view-cell,
.vui-stack-view .stack-view-table .stack-view-parent .stack-view-cell,
.vui-stack-view .stack-view-table .stack-view-parent-i .stack-view-cell,
.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell,
.vui-stack-view .stack-view-table .stack-view-row-i .stack-view-cell {
  border-bottom: 1px solid $light-grey-30;
}

.vui-stack-view .stack-view-table .stack-view-row-i .stack-view-cell:first-child,
.vui-stack-view .stack-view-table .stack-view-row-i .stack-view-cell:first-child span,
.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell:first-child,
.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell:first-child p {
  font-weight: 600;
}

// TOC
$toc-border: $panel-border;

div.settingsBlockHeader {
  margin-bottom: 5px;
  background: #ffffff;
}

span.settingsBlockTitle, h2.settingsBlockTitle {
  margin-left: 0px;
}

.vui-toc-tabs {
  background-color: $vui-background-color;
  color: $vui-text-color;
  border: none;
  border-right: $toc-border;
  height: 99%;

  ul {
    li {
      a {
        color: inherit;
        font-weight: normal;
      }

      &.active {
        background-color: $green-opacity-7;

        a {
          color: inherit;
          text-decoration: none;
        }
      }

      &:hover {
        background-color: $green-opacity-4;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}

// Clarity UX provided styles

/* WEBCLIENT */

/* AUTH PAGE */
span.loginLabel {
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.05em;
}

#loginForm p#sessionID {
  width: 257px;
}

#center-pane.vui-panel .center-pane-container .panel-inner {
  max-width: 100%;
  margin-left: 10px;
}

.nav.nav-tabs > li > a {
  margin-top: -3px;
}

.summary-icons-block .resource-label {
  font-size: 1em;
}

.vui-portlet .portlet-titlebar {
  border-radius: 3px;
  padding-top: 6px;
  /* font-size: 12px; */
}

.vui-portlet .portlet-titlebar .titlebar-text {
  font-size: 16px;
  padding-left: 4px;
  font-weight: 600;
}

.vui-portlet .portlet-footer {
  padding-bottom: 7px;
  font-weight: 500;
}

.vui-icon-search-input {
  opacity: 1;
}

.search .search-field {
  font-size: 14px;
  padding-left: 2px;
  margin-left: 23px;
  width: 300px;
}

ul.primary-nav li {
  padding-top: 2px;
}

.vui-icon-object-nav-pan-hover {
  margin-top: 5px;
  padding-right: 5px;
}

#right-panel {
  .titlebar {
    padding-top: 0px;
    padding-left: 8px;
  }
}

.nav.nav-tabs {
  padding-left: 0;
  /* padding-bottom: 3px; */
  margin-top: -1px;
}

.outer-tab-content {
  margin-top: 10px;
}
.vui-portlets-container .vui-portlet {
  margin-right: 16px;
}

.portlet-footer .vui-portlet {
  border-radius: 3px;
  margin-right: 0;
}

.portlet-footer { // (footerLinks)
  font-weight: 500;
}

.panel-content {
  padding-top: 0px;
  margin-top: 0px;
}

.selected span.object-navigator-label {
  font-weight: 800;
}

span.object-navigator-label {
}

span.vui-icon-home {
  background-position: -2px -1px;
  margin-top: -2px;
}

.object-navigator-list a.object {
  padding: 2px 0 2px 8px;
}

.objNavSeparator {
  padding-top: $separator-vertical-spacing;
  margin-top: $separator-vertical-spacing;
  // assumed to be vertically listed elements
  border-top: 1px solid $light-grey-30;
}

.nav.nav-tabs > li > a {
  padding-bottom: 13px;
}

div[vui-tree-view] .k-in {
  padding: 2px 0 2px;
}

.vui-toc-tabs ul li.active, {
  background-color: $clr-row-selection-color;
}
.vui-toc-tabs ul li:hover {
  background-color: $clr-row-hover-color;
}

.controlcenter-category {
  padding-bottom: 0.2em;
  padding-left: 0;
}

.controlcenter {
  margin-bottom: 2.2em;
}

.fill-parent {
  overflow: visible;
}

#center-pane.vui-panel .center-pane-container .content-no-tab, #center-pane.vui-panel .center-pane-container .outer-tab-content {
  max-width: 100%;
}

.vui-tertiary-tabs .vui-tab-content {
  background: none;
}

.vui-toc-tabs ul > li:hover {
  background: $clr-row-hover-color;
}

.modal-backdrop {
  opacity: 1; /* Temporary fix for transparent dialogs until we start using Clarity modals */
  background-color: rgba(0, 0, 0, 0.6);
}

.vui-icon-placeholder.vui-icon-object-nav-history-left-hover,  .vui-icon-placeholder.vui-icon-object-nav-history-right-hover {
  margin-top: -2px;
}

.vui-icon-placeholder.vui-icon-object-nav-history-right-hover {
  margin-top: -2px;
}

.split-view .details {
  box-shadow: 1px 0 0 #ddd inset, -1px 0 0 #ddd inset;
}

#right-panel .panel-inner .panel-content {
  top: 27px;
}

.settingsBlockTitle {
  color: $clr-title-color;
  font-size: 18px;
  font-weight: 400;
  padding: 4px 0 4px 2px;
}

div.settingsBlockHeader {
  background: none;

  .settingsBlockTitle {
    font-size: 22px;
    font-weight: 400;
    line-height: 22px;
    /* it was a span, now making it h2
      and we dont want to mess up the layout
      so we set the display property to inline
      to make the h2 display like a span
      */
    display: inline;
  }
}

.resource-meter .progress {
  background-color: #e4e4e4;
  border-radius: 3px;
}

.resource-meter .normal .progress .progress-bar {
  background-color: #0094d2;
}

.k-menu > .k-state-hover {
  background: $clr-row-hover-color;
  color: $vui-text-color;
}

.k-menu > .k-state-hover > .k-link {
  color: inherit;
}

.k-menu .k-item {
  border: 0 none;
  padding: 5px 0 3px;
}

ul.k-menu-vertical {
  border-radius: 3px;
  border: 1px solid #ccc;
  box-shadow: -1px 2px 3px 1px rgba(0, 0, 0, 0.08);
}

.vui-popup.vui-dialog .titlebar {
  padding: 0 10px 0 !important;
}

.vui-popup.vui-dialog .panel-content {
  margin-top: 0;
}

/* TODO: instead of form put under .form-group */

/* Jehad's Edits */

ul.nav.nav-tabs {
  padding-left: 0;
}

object-navigator .panel-inner .titlebar {
  display: none;
}

.panel-content div.titlebar {
  display: block;
  padding-top: 0;
}

span.object-navigator-counter {
  margin-top: 1px;
  background-color: #fff;
  color: $clr-gray;
  padding-top: 5px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.nav-bottom-block.panel-content.objNavSeparator {
  margin-left: 0;
  margin-right: 0;
}

.vui-portlet {
  border-radius: 3px;
  box-shadow: 1px 3px 3px $clr-row-hover-text-color;
}

.vui-portlet .portlet-footer {
  padding-bottom: 8px;
}

.vui-portlet .portlet-titlebar {
  padding-top: 4px;
  padding-bottom: 6px;
  padding-left: 12px;
}

.vui-portlet .portlet-titlebar .titlebar-text {
  font-size: 16px;
  padding-left: 3px;
  font-weight: 400;
}

span.titlebar-left-icons {
  display: none;
}

// affects object navigator icons too
.nav.nav-tabs li a {
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 20px;
}

.col-xs-5, .vui-portlets-container.two-columns > .vui-portlet:nth-child(odd) {
  width: 49%;
  margin-right: 1%;
  box-sizing: border-box;
}

.col-xs-5, .vui-portlets-container.two-columns > .vui-portlet:nth-child(even) {
  float: right;
  width: 49%;
  margin-right: 1%;
  box-sizing: border-box;
}

center-panel .fill-parent .fill-parent .fill-parent [vx-view] > div, center-panel .outer-tab-content > .fill-parent [vx-view] > div {
  max-width: 100%;
}

object-navigator .vui-tabs-container .vui-primary-tabs ul.nav.nav-tabs {
  padding-left: 13px;
}

.object-navigator-heading {
  text-transform: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

center-panel .fill-parent .fill-parent .fill-parent, center-panel .outer-tab-content > div.fill-parent {
  max-width: 100%;
}


center-panel {
  .k-pager-info.k-label {
    line-height: 20px;
  }
}

span.object-navigator-history-button-label {
  color: $clr-gray;
  text-transform: initial;
  letter-spacing: 0;
}

//.vui-panel.top-level.left-panel .panel-inner > .panel-content {
//  margin-top: -20px;
//}

//.object-navigator.panel-content {
//  margin-top: 6px;
//}

.vui-wizard .wizard-modal-footer,
.vui-wizard .wizard-modal-titlebar {
  background-color: $center-pane-color;
  background: $center-pane-color;
}

.merged-input .search-query {
  margin-bottom: 0;
  border-radius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  color: inherit;
  text-indent: 0px;
}

.merged-input .search-input-container {
  margin-top: 9.9px;
}

.search-form-panel {
  max-height: 76px;
}

.merged-input .vui-icon-search-input {
  position: static;
  min-width: 16px;
}

button[name^="editCdrom"] {
  margin: 0;
}

.new-vm-name-and-folder-page {
  padding: 0;
}

[name="hardwareForm"] {
  value-in-units {
    .vui-dropdown {
      input[type="text"] {
        width: 100px;
      }
    }
    .form-group {
      padding: 0px;
      margin-bottom: 0px;
    }
  }
}

li[role="treeitem"],
td[role="gridcell"] {
  span::selection, a::selection {
    background: inherit;
  }
}

.alert .alert-item span {
  padding-left: 0;
  margin-right: 0;
}

// Icon replacing the error popup in release builds is shown at a fixed location.
// It needs enough space to stay clear of label "more tasks" in all languages
.warning-icon {
  bottom: 6px;
  right: 120px;
  position: absolute;
}

// Work-around to keep the error popup close icon to the right
.titlebar-right-icons {
  position: absolute;
  right: 20px;
}

// restricting the size of
// Object Navigator icons to 18x18
.object-navigator-list .object span:first-child {
  max-width: 18px;
  max-height: 18px;
}