[class^="vui-icon-"],
[class*=" vui-icon-"],
.vui-icon-Sprite,
.vuiVmwareLogo,
.vui-icon-action-add,
.vui-icon-action-delete,
.vui-icon-action-edit,
.vui-icon-action-copy,
.vui-icon-action-more,
.vui-icon-action-refresh,
.vui-icon-timer,
.vui-icon-link,
.vui-icon-link-break,
.vui-icon-caret-gray-down,
.vui-icon-caret-gray-right,
.vui-icon-caret-dark-gray-down,
.vui-stack-view .stack-view-table .stack-view-parent.open .stack-view-cell:first-child:before,
.vui-stack-view .stack-view-table .open.stack-view-parent-i .stack-view-cell:first-child:before,
.vui-icon-caret-dark-gray-right,
.vui-stack-view .stack-view-table .stack-view-parent .stack-view-cell:first-child:before,
.vui-stack-view .stack-view-table .stack-view-parent-i .stack-view-cell:first-child:before,
.vui-icon-caret-white-down,
.vui-icon-caret-black-down,
.stackview .sv-expanded,
.vui-icon-caret-black-right,
.stackview .sv-collapsed,
.vui-icon-signpost-info,
.vui-icon-signpost-info-hover,
.vui-icon-error-stack,
.vui-icon-datagrid-caret-up,
.vui-icon-datagrid-caret-down,
.vui-icon-datagrid-cancel,
.vui-icon-datagrid-cancel-disabled,
.vui-icon-datagrid-status-green,
.vui-icon-datagrid-status-info,
.vui-icon-datagrid-status-red,
.vui-icon-datagrid-status-unknown,
.vui-icon-datagrid-status-yellow,
.vui-icon-datagrid-status-notApplicable,
.vui-icon-dialog-maximize,
.vui-icon-dialog-reduce,
.vui-icon-dialog-tiwo,
.vui-icon18-dialog-close,
.vui-icon18-dialog-close-disabled,
.vui-icon18-dialog-close-hover,
.vui-icon18-dialog-close-active,
.vui-icon18-dialog-help,
.vui-icon18-dialog-help-disabled,
.vui-icon18-dialog-help-hover,
.vui-icon18-dialog-help-active,
.vui-icon-folder,
.vui-icon-folder-add,
.vui-icon-folder-open,
.vui-icon-home,
.vui-icon-home-white,
.vui-icon-home-burger-white,
.vui-icon-info,
.vui-icon-nav-history,
.vui-icon-object-nav-pan,
.vui-icon-object-nav-pan-hover,
.vui-icon-object-nav-history-left,
.vui-icon-object-nav-history-left-hover,
.vui-icon-object-nav-history-left-disabed,
.vui-icon-object-nav-history-right,
.vui-icon-object-nav-history-right-hover,
.vui-icon-object-nav-history-right-disabled,
.vui-icon-power-on,
.vui-icon-power-off,
.vui-icon-power-suspend,
.vui-icon-refresh,
.vui-icon-dropdown-white,
.vui-icon-save,
.vui-icon-save-search,
.vui-icon-search,
.vui-icon-search-input,
.vui-icon-slide-panel-pinned,
.vui-icon-slide-panel-unpinned,
.vui-icon-critical,
.vui-icon-status-error,
.vui-icon24-error,
.vui-icon32-error,
.vui-icon-status-info,
.vui-icon24-info,
.vui-icon32-info,
.vui-icon-question,
.vui-icon-status-question,
.vui-icon24-question,
.vui-icon32-question,
.vui-icon-success,
.vui-icon-status-success,
.vui-icon24-success,
.vui-icon32-success,
.vui-icon-warning,
.vui-icon-status-warning,
.vui-icon24-warning,
.vui-icon32-warning,
.vui-icon-tag,
.vui-icon-tag-add,
.vui-icon-tag-assign,
.vui-icon-tag-remove,
.vui-icon-tag-move,
.vui-icon-multiple-tags,
.vui-icon-task-error,
.vui-icon-task-successful,
.vui-icon-task-queued,
.wizard-steps-completed > span[class*='vui-icon'],
.vui-icon-banner-close,
.vui-icon-banner-close-active,
.vui-icon-banner-close-disabled,
.vui-icon-user,
.vui-icon-users,
.object-navigator .object-navigator-list > li > a.object:hover .vui-icon-object-nav-pan {
  background-image: url("img/vuiIconSpriteDark.png");
}