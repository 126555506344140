@import "../clarity-colors";
@import "vui-icon-sprite-dark";
@import "vsphere-sprite-dark";

//== Typography
// ----------------------------------------------------------------------------
//## Common styles for text, inputs and more

$vui-background-color: #1b2a32;
$vui-header-color: #e9ecef;
$vui-text-color: #adbbc4;

$clr-row-hover-color: #29414e;
$clr-row-hover-text-color: $vui-text-color;
$clr-row-selection-color: #324f61;

$clr-border-color: #485764;

$clr-table-header-background-color: $vui-background-color;
$clr-table-header-text-color: $vui-text-color;
$clr-table-cell-background-color: #22343c;
$clr-table-cell-text-color: $vui-text-color;
$clr-table-border-color: $clr-border-color;
$clr-table-border-style: 1px solid $clr-table-border-color;
$clr-title-color: $vui-header-color;

$clr-button-default-color: #49AFD9;
$clr-button-default-hover-color: #57c7ea;

$clr-badge-background-color: #6d7884;
$clr-badge-text-color: #000000;

$clr-bar-chart-background-color: #324650;
$clr-bar-chart-fill-color: #49AFD9;

//** Background color for `<body>`.
$body-bg: $vui-background-color !default;

$center-pane-color: $vui-background-color;
$green-opacity-7: rgba(183, 229, 153, 0.7);
$green-opacity-4: rgba(183, 229, 153, 0.4);

$tree-item-selected: $clr-row-selection-color;
$tree-item-hover: $clr-row-hover-color;

// Non-standard colors ...
$portlet-border: 1px solid #d8d8d8;
$resource-bar-color: #87b0de;
$selected-modal-text-color: #FFFFFF;

// grey menu
$grey-menu-background: $clr-near-white;
$grey-menu-background-selected: $clr-near-white;
$menu-font-color: $clr-near-white;

$panel-border: 1px solid $clr-border-color;


%clr-default-color {
  background-color: $vui-background-color;
  color: $vui-text-color;
}

%clr-hover-color {
  background-color: $clr-row-hover-color;
  color: $vui-text-color !important;;
}

%clr-selection-color {
  background-color: $clr-row-selection-color;
  color: $vui-text-color !important;
}

%clr-table-cell-color {
  background-color: transparent;
  color: $vui-text-color;
}

%clr-button-style {
  background-color: transparent;
  color: $clr-button-default-color;
  border: 1px solid $clr-button-default-color;
}

%clr-button-active-style {
  background-color: $clr-button-default-color;
  color: $clr-badge-text-color;
  border: 1px solid $clr-button-default-color;
}

%clr-modal-color {
  background-color: $clr-table-cell-background-color;
  color: $vui-header-color;
}

%clr-modal-body-color {
  background-color: $clr-table-cell-background-color;
  border: $clr-border-color 1px solid;
  color: $vui-text-color;
}

// other misc overrides

::-webkit-scrollbar {
  background: $clr-row-selection-color;
}

::-webkit-scrollbar-thumb {
  background: $clr-row-hover-color;
}

#horizontal-panel {
  background-color: $vui-background-color;
}

bottom-panel toggle-splitter {
  background: $vui-background-color;
}

table {
  border-color: $clr-border-color;
}

//headers
h1, h2, h3, h4 {
  color: $vui-header-color;
}

.titlebar {
  color: $vui-header-color;

  .vui-wizard .wizard-modal-titlebar,
  .object>.titlebar-title {
    color: $vui-text-color;
  }
}

.summary-icons-block {
  .resource-label {
    color: $vui-text-color;
  }
  .resource-value {
    color: $vui-header-color;
  }
}

// button groups
button.btn.btn-button.btn-sm.active {
  color: $clr-badge-text-color;
  background-color: $clr-button-default-color;
}

// links
a, a:hover, a:link:hover,
a:link:visited, a:visited,
span.arrow {
  color: $clr-button-default-color;
}

// ACTIONS link
.titlebar .object>[role=titlebar-actionable]
.summary-action-link [role=actionable-title], .icon-dropdownToggle {
  color: $clr-button-default-color;
}

.vx-portlets .col .vx-portlet {
  background: $vui-background-color;
  border: 1px solid $clr-gray;
  color: $vui-text-color;
}

.object-navigator .object {
  color: $vui-text-color;
}

.controlcenter-shortcut-label {
  color: $vui-text-color;
}

.vui-primary-tabs .vui-tab-content {
  background: $vui-background-color;
  color: $vui-text-color;
}

.k-widget.k-treeview {
  color: $vui-text-color;
}

.modal-dialog,
.vui-panel,
.vui-wizard {
  .modal-content,
  .vx-tab-wrapper,
  .wizard-modal-content,
  [vx-navi-tab-headers] {

    .about-vsphere-dialog-legal-section {
      color: $vui-text-color;
    }

    //edit vcenter general settings
    .content-area.gs-modal-content[_ngcontent-c12] {
      background: $vui-background-color;
    }

    .nav.nav-tabs > li.active > a, tab.iconClass {
      color: $vui-header-color;
    }

    .nav.nav-tabs li a, tab.iconClass {
      color: $vui-text-color;
    }

    &[level=first], &[level=details] {
      .nav.nav-tabs>li>a {
        color: $vui-text-color;
      }
      .nav.nav-tabs>li.active>a {
        color: $vui-header-color;
        border-bottom: 3px solid $clr-button-default-color;
      }
    }

    .pre-scrollable {
      background-color: $vui-background-color;
      color: $vui-text-color;
    }
  }
}

// Tabs
.vui-toc-tabs ul {
  li {
    color: $vui-text-color;

    &:hover {
      @extend %clr-hover-color;
    }

    &.active {
      @extend %clr-selection-color;
      a {
        color: inherit;
      }
    }
  }
}

// Tree view
div[vui-tree-view] {
  .k-state-selected {
    @extend %clr-selection-color;
  }
  .k-state-hover {
    @extend %clr-hover-color;
  }
}

.k-block, .k-widget {
  @extend %clr-default-color;
}

.k-widget.k-splitter {
  .split-view .details {
    @extend %clr-default-color;
    box-shadow: 1px 0 0 $clr-table-border-color inset, -1px 0 0 $clr-table-border-color inset;
    div[vui-datagrid].related-events .k-grid.k-widget {
      border: $clr-table-border-style;
    }
  }
}

.k-splitter .k-splitbar.k-splitbar-vertical {
  border-bottom-color: $clr-table-border-color;
}

// Tasks grid hover
span.k-link.k-state-active.k-state-border-right,
li.k-item.k-column-item.k-state-hover.k-state-border-right {
  background-color: $clr-row-selection-color;
}

// Tasks grid filter
.k-content.k-filter-menu.k-filterable,
.k-menu .k-item div{
  background-color: $vui-background-color;

  .k-filter-menu {
    button.k-button+.k-button {
      @extend %clr-button-style;
    }
    button.k-button.k-primary {
      background-color: $clr-button-default-color;
    }
  }
}
.k-grid .k-grid-header:after,
.k-grid .k-grid-header:before {
  background: $clr-border-color;
}

.flex-grid .k-grid.k-widget .k-grid-content .k-scrollbar-vertical>div {
  background: $vui-background-color;
}


.k-grid-header .k-header>.k-link,
.k-header,
.k-panelbar>li>.k-link,
.k-tabstrip-items .k-link,
.k-treemap-title {
  color: $vui-header-color;
}

.k-filter-row th,
.k-grid-header th.k-header {
  border-color: $clr-border-color;
}

// Tree list
.k-treelist.k-grid.k-widget {
  @extend %clr-default-color;
  border-color: $clr-border-color;
  .k-grid-header {
    @extend %clr-default-color;
    border-color: $clr-border-color;
    th {
      background: $vui-background-color;
      color: $vui-header-color;
      &.k-header {
        background: $vui-background-color;
        color: $vui-header-color;
      }
    }
    .k-link:link {
      color: $vui-header-color;
    }
  }

  .k-grid-content-locked, .k-grid-content {
    tbody tr.k-alt,
    tbody tr:not(.k-alt) {
      background: $vui-background-color;
    }
    tbody tr {
      td {
        border-color: $clr-border-color;
        color: $vui-text-color;
      }
    }
  }
}
span.object-navigator-counter,
left-panel span.object-navigator-counter {
  background: $clr-badge-background-color;
  border-color: $clr-badge-background-color;
  color: $clr-badge-text-color;
}

// Data grid
.k-grid-content-locked:last-child,
.k-grid-content:last-child {
  border-bottom: 1px solid $clr-border-color;
}
.vui-datagrid,
div[vui-datagrid] {
  background: $vui-background-color;

  .k-grid-content {
    background-color: $clr-table-cell-background-color;
    color: $clr-table-cell-text-color;
  }
  .k-widget, &.k-widget {

    &.k-grid {
      background-color: $vui-background-color;
      color: $vui-text-color;

      .k-grid-content, .k-grid-content-locked {
        border-left: $clr-table-border-style;
        border-right: $clr-table-border-style;
        tbody tr {
          @extend %clr-table-cell-color;

          &.k-state-selected {
            @extend %clr-selection-color;
          }

          &.k-state-selected:hover,
          &:hover {
            @extend %clr-hover-color;
            td {
              background-color: inherit;
              color: inherit;
            }
          }

          &.k-grouping-row td,
          &.k-resize-handle-inner,
          td.k-group-cell,
          td {
            background-color: inherit;
            border-color: $clr-table-border-color;
          }
        }
      }

      .k-header {
        &.k-grid-toolbar {
          @extend %clr-default-color;
        }
      }

      .k-grid-header, .k-grid-header, div[vui-datagrid] {
        border: $clr-table-border-style;
        border-left: none;
        border-bottom: 2px solid $clr-table-border-color;
        background-color: $clr-table-header-background-color;
        color: $clr-table-header-text-color;

        th.k-header {
          border-left: $clr-table-border-color 1px;
          background-color: $clr-table-header-background-color;
          color: $clr-table-header-text-color;
        }
        th.k-header:first-child {
          border-left: $clr-table-border-style;
        }
        th:not(:last-child) {
          border-right: $clr-table-border-style;
        }

        .k-link:link {
          color: $vui-text-color;
        }
      }

      .k-pager-wrap.k-grid-pager {
        border: $clr-table-border-style;
        border-top: 2px solid $clr-table-border-color;
        background-color: $clr-table-header-background-color;
        color: $vui-text-color;
        .k-pager-info {
          color: $vui-text-color;
        }
      }
    }
  }
}
.object-navigator-heading {
  color: $vui-text-color;
}

// About vSphere
.about-vsphere-dialog-legal-section {
  color: $vui-text-color;
}

// VUI Feedback Modal
.vui-popup.vui-dialog.vfeed-dialog {
  border: 1px solid $vui-background-color;

  .panel-content,
  .titlebar.ui-draggable-handle {
    background-color: $clr-table-cell-background-color;

    span.titlebar-text {
      background-color: $clr-table-cell-background-color;
    }
  }
}

// VUI Modals
.modal-body, .edit-custom-attribute {
  div[vui-datagrid] {
    background-color: transparent;
  }
}

// VUI Wizard
.vui-wizard, .vui-popup {
  @extend %clr-default-color;
  border: none;
  background-color: $clr-table-cell-background-color;

  .km-widget {
    .titlebar-text {
      color: $vui-header-color;
    }
  }

  .wizard-modal-titlebar.ui-draggable-handle {
    border-bottom: $clr-border-color 1px solid;

    span.titlebar-text {
      @extend %clr-modal-color;
    }
  }

  .wizard-modal-body {
    .wizard-content-container {
      background-color: $clr-table-cell-background-color;

      .wizard-content-title, .wizard-content-instructional-text {
        background-color: $clr-table-cell-background-color;
      }

      .wizard-content-header-area {
        border-bottom: $clr-border-color 1px solid;
      }
    }

    .wizard-panel-TOC {
      .wizard-steps-list {
        .wizard-steps-current a {
          background-color: $clr-row-selection-color;
        }
      }
    }

    .create-role-view {
      [role=privilege-list] {
        @extend %clr-modal-body-color;

        li.active {
          background-color: $clr-row-selection-color;
          color: $selected-modal-text-color;
        }
      }

      .no-selection-group, .priv-group {
        @extend %clr-modal-body-color;
      }

      .privileges-header {
        @extend %clr-modal-body-color;
      }
    }
  }

  &.vui-dialog {
    .titlebar.ui-draggable-handle .titlebar-text,
    .panel-content {
      @extend %clr-default-color;
    }

    .vfeed-content {
      form,
      .form {
        .form-group {
          textarea {
            @extend %clr-default-color;
          }

          button {
            cursor: pointer;
            display: inline-block;
            -webkit-appearance: none!important;
            border-radius: .125rem;
            border: 1px solid;
            min-width: 3rem;
            max-width: 15rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;
            line-height: 1.5rem;
            letter-spacing: .12em;
            font-size: .5rem;
            font-weight: 500;
            height: 1.5rem;
            padding: 0 .5rem;
            border-color: #49afd9;
            background-color: transparent;
            color: #49afd9;
          }
        }
      }
    }
  }

  .wizard-modal-titlebar .titlebar-text {
    @extend %clr-default-color;
  }

  .wizard-content-title, h3, h4 {
    @extend %clr-default-color;
  }

  .wizard-content-container {
    @extend %clr-default-color;
  }

  .wizard-panel-TOC {
    .wizard-steps-list li {
      a {
        color: $vui-text-color;
      }
      .wizard-steps-current a {
        background-color: $clr-row-selection-color;
        color: $vui-header-color;
      }
      .wizard-steps-not-available a {
        color: $vui-text-color;
      }
    }
  }
}

settings-view-header .settingsViewHeader .settingsBlockTitle {
  color: $vui-header-color;
}

.form, form {
  .form-group.row>[class*=col-]>label,
  .form-group.row>[class*=col-]>span,
  .form-group>label:first-child,
  .form-group>span:first-child,
  .form-group.row>[class*=col-]>label,
  .form-group.row>[class*=col-]>span,
  .form-group>label:first-child,
  .form-group>span:first-child {
    @extend %clr-table-cell-color;
  }
}

.select select {
  color: $vui-text-color;
}

assets-grid-view .assets-grid .datagrid .datagrid-body .datagrid-row {
  &.datagrid-selected.focused, &.focused {
    @extend %clr-selection-color;
  }
}

.vui-action-bar {
  .vui-action-label {
    color: $vui-text-color;

  }

  ul li a:hover:not(.disabled) {
    @extend %clr-hover-color;
  }
}


.category-node-object-navigator [role=container] [role=group] [role=item]>.object {
  &.selected {
    @extend %clr-selection-color;
  }
  &:hover {
    @extend %clr-hover-color;
  }
}

.vui-panel [vx-tab-headers] .btn-group,
.vui-panel [vx-navi-tab-headers][level=second] .btn-group {
  background: transparent;

  >.btn, >button {
    @extend %clr-button-style;

    &:not(:last-child) {
      @extend %clr-button-style;
    }

    &:not(:first-child) {
      margin-left: -1px;
    }

    &:last-child {
      @extend %clr-button-style;
      border-left: none;
    }

    &:last-child:nth-child(2) {
      border-left: 1px solid $clr-button-default-color;
    }

    &:first-child {
      @extend %clr-button-style;
      border-right: none !important;
    }

    &.active {
      background-color: $clr-button-default-color !important;
      color: #000 !important;
      &:hover {
        background-color: $clr-button-default-hover-color;
      }
    }

    &:not(.active):hover {
      border: 1px solic $clr-button-default-hover-color;
      color: $clr-button-default-hover-color;
      background-color: transparent;
    }
  }
}

.vui-panel [vx-tab-headers] .btn-group {
  height: auto;
}

// New Search
.simple-search-left-pane {
  vsc-search-left-pane {
    @extend %clr-default-color;
  }
}

.sidenav .nav-group .nav-list {
  .nav-link.active {
    @extend %clr-selection-color;
  }
  .nav-link:hover {
    @extend %clr-hover-color;
  }
}

.simple-search {
  .search-header,
  .panel {
    @extend %clr-default-color;
  }
}
// End of new Search

//Search Box
.box-container .box-container-highlight .dropdown .flex-header-container .tid-quick-search {
  background-color: $grey-menu-background;
  color: $vui-text-color;
}

//Context Menu
li.k-item.menu-header {
  background-color: $vui-background-color;
  color: $vui-header-color;
  border-bottom: 1px solid $clr-border-color;
}

//New Dark Theme Sprites
.k-column-menu .k-sprite,
.k-drop-hint, .k-gantt-views>.k-current-view>.k-link:after,
.k-grid-mobile .k-resize-handle-inner:after,
.k-grid-mobile .k-resize-handle-inner:before,
.k-grouping-dropclue, .k-icon, .k-mobile-list .k-check:checked,
.k-mobile-list .k-edit-field [type=checkbox]:checked,
.k-mobile-list .k-edit-field [type=radio]:checked,
.k-pager-numbers .k-current-page .k-link:after,
.k-scheduler-toolbar>ul.k-scheduler-views>li.k-current-view>.k-link:after,
.k-tool-icon {
  background-image: url(img/sprite_dark_theme.png);
  border-color: transparent;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), not all {
  .k-callout, .k-column-menu .k-sprite, .k-drop-hint,
  .k-grouping-dropclue, .k-icon:not(.k-loading),
  .k-mobile-list .k-check:checked, .k-mobile-list .k-edit-field [type=checkbox]:checked,
  .k-mobile-list .k-edit-field [type=radio]:checked,
  .k-state-active .k-tool-icon, .k-state-active.k-state-hover .k-tool-icon,
  .k-state-hover .k-tool-icon, .k-state-selected .k-tool-icon,
  .k-state-selected.k-state-hover .k-tool-icon, .k-tool-icon {
    background-image: url(img/sprite_2x_dark_theme.png);
    border-color: transparent;
  }
}

.k-menu.k-menu-vertical.k-context-menu {
  @extend %clr-default-color;
  border-color: $clr-border-color;
  .menu-header.k-state-hover span.k-link {
    color: $vui-header-color;
  }
}

ul.k-group.k-menu-group.k-popup.k-reset {
  @extend %clr-default-color;
}

li.k-item.is-separated {
  border-color: $clr-border-color;
}

.k-active-filter,
.k-state-active,
.k-state-active,
.k-tabstrip.k-state-active {
  @extend %clr-selection-color;
  &:hover {
    @extend %clr-hover-color;
  }
}

ul.k-group.k-menu-group.k-popup.k-reset {
  border-color: $clr-border-color;
}
// End of Context Menu

// Search bar
.main-nav header.header {
  .tid-quick-search vsc-search-header .flex-header-container .box-container-highlight {
    @extend %clr-default-color;
    input#search-term-ref[name="search-term"] {
      color: $vui-text-color;
    }
  }

  search-overview {
    @extend %clr-default-color;
    search-item-row {
      @extend %clr-default-color;
      &.highlight {
        background-color: $clr-row-selection-color;
      }
    }
  }
}
// End of search bar

//Section Header
span.settingsBlockTitle.settingsViewHeader {
  color: $vui-header-color;
}

// VUI widget states
.k-mobile-list .k-edit-label.k-check:active,
.k-mobile-list .k-item>.k-label:active,
.k-mobile-list .k-item>.k-link:active,
.k-mobile-list .k-recur-view .k-check:active,
.k-mobile-list .k-scheduler-timezones .k-edit-field:nth-child(2):active {
  @extend %clr-selection-color;
  &:hover {
    @extend %clr-hover-color;
  }
}

.k-dropdown .k-state-focused,
.k-filebrowser-dropzone,
.k-list>.k-state-hover,
.k-pager-wrap .k-link:hover,
.k-scheduler .k-scheduler-toolbar ul li.k-state-hover,
.k-splitbar-horizontal-hover:hover,
.k-splitbar-vertical-hover:hover,
.k-state-hover, .k-state-hover:hover {
  @extend %clr-hover-color;
}
// End of VUI widget states

// VUI table borer
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  border-color: $clr-border-color;
}

// VUI general form controls.
button, input, optgroup, select, textarea {
  background-color: inherit;
}

// Chart
.overview-charts-view .chart-container {
  .overviewchart, .chart {
    @extend %clr-default-color;
    .chart-view .chart-loading-data {
      @extend %clr-default-color;
    }
  }
}

div.advperfcharts-view .chart-container {
  @extend %clr-default-color;
  .chart, .chart-legend {
    @extend %clr-default-color;
  }
}

div.advperfcharts-view .chart-view .chart-title-bar {
  @extend %clr-default-color;
}

// Network - property view
div.vx-property-view {
  @extend %clr-default-color;
}

// Network - utilization chart
span, div {
  .dvs-meter-white {
    background: $vui-background-color;
  }
}



left-panel object-navigator {
  .nav-top-block.obj-nav-counter,
  .nav-top-pane-relations-mode.primary-list-item,
  .primary-list-item {
    background: $vui-background-color;
    border-top: 1px solid $clr-border-color;
    border-bottom: 1px solid $clr-border-color;
  }
}

// License grid
.licenses-grid .datagrid .datagrid-body .datagrid-row {
  &.datagrid-selected.focused,
  &.focused {
    background: $clr-row-selection-color;
  }
}

serial-key-licenses-multi-selection-grid {
  .sync-licenses-add-grid {
    .datagrid .datagrid-body .datagrid-row {
      &.datagrid-selected.focused,
      &.focused {
        background: $clr-row-selection-color;
      }
    }
  }
}

.license-details-rename-control {
  background: transparent;
}


.km-content.km-widget {
  @extend %clr-default-color;
}

.km-right {
  .km-popup-arrow:before {
    border-color: transparent $clr-border-color;
  }
  .km-popup-arrow:after {
    border-color: transparent $clr-border-color;
  }
}

.km-popup-arrow:after, .km-popup-arrow:before {
  border-color: $clr-border-color transparent;
}

.km-popup.km-pane {
  border-color: $clr-border-color;
}

.km-footer {
  background: $vui-background-color;
}

// VUI combo box
.k-autocomplete.k-state-default,
.k-dropdown-wrap.k-state-default,
.k-multiselect.k-header,
.k-numeric-wrap.k-state-default,
.k-picker-wrap.k-state-default {
  background: $vui-background-color;
  color: $vui-text-color;
  border-color: $clr-border-color;
}

.k-content,
.k-editable-area,
.k-group,
.k-list-container,
.k-panel>li.k-item,
.k-panelbar>li.k-item,
.k-tiles {
  @extend %clr-default-color;
  border-color: $clr-border-color;
}

.k-state-default>.k-select {
  border-color: $clr-border-color;
}

.k-list>.k-state-hover {
  border-color: $clr-border-color;
}

// Database
.fill-parent.datastore-file-browser-controller {
  background: $vui-background-color;
}

div.storage-vui-splitter .datastoreFileBrowserTree {
  border-color: $clr-border-color;
  background-color: $vui-background-color;
  color: $vui-text-color;
}

div.vmfs-datastore-multipathing-details {
  background-color: $vui-background-color;
}

.object-navigator-title {
  background-color: $vui-background-color;
  color: $vui-text-color;
  border-color: $clr-border-color;
}

div.objNavSeparator {
  border-top-color: $clr-border-color;
}

li.objNavSeparator {
  border-top-color: $clr-border-color;
}

// Signpost text
#clr-sign-post-content {
  .info-msg-color {
    color: $vui-text-color;
  }
}

.signpost-content-body h3{
  color: $vui-text-color;
  background-color: $clr-table-cell-background-color;
}

// Splitview
.iso-chooser-splitter {
  [kendo-splitter] {
    border-right: 1px solid $clr-border-color;
  }

  .chooser-panel-title {
    background: $vui-background-color;
    border-bottom: 1px solid $clr-border-color;
  }

  .chooser-panel {
    background: $vui-background-color;
    border: 1px solid $clr-border-color;
    border-right:none;
  }
}

// Stack block - highlighted - when there are errors
// !important because the same rule in light theme uses !important as well...
// TODO update light theme css and remove  !important on both side.
clr-stack-block.vm-ui-stackBlockEdited>.stack-block-label {
  background-color: $clr-row-selection-color !important;
}

clr-stack-block {
  clr-stack-content {
    clr-dropdown.dropdown {
      button.btn.btn-outline-primary.dropdown-toggle {
        color: $vui-text-color;
      }
    }
  }
}

// Administration -> Certificates
.certificate-details[_ngcontent-c11] .cert-tree[_ngcontent-c11] span.selected[_ngcontent-c11] {
  background: $clr-row-selection-color;
}

// Progress bar / spinner
.progress-centered {
  background: $clr-row-selection-color;
}

.progress-bordered, .progress.active {
  border-color: $clr-border-color;
}

//Threshold bar chart
.threshold-bar-chart-container {
  .threshold-bar-chart-component___bar-group-label,
  .threshold-bar-chart-component___content-group {
    color: $vui-text-color;
  }
}

//Threshold bar chart
.failover-usage-reservation-info-container {
  .threshold-bar-chart-container {
    .threshold-bar-chart-component {
      .threshold-bar-chart-component___content-group {
        .threshold-bar-chart-component___bar-groups-and-markers-container {
          .threshold-bar-chart-component___bar-group-container {
            .threshold-bar-chart-component___bar-group-inner-container {
              background-color: $clr-row-selection-color;
              .threshold-bar-chart-component___usage-bar {
                background-color: $clr-row-hover-color;
                border: 2px solid $clr-border-color;
              }
            }
          }
        }
      }
    }
  }
}
//Action menu header
li.k-item.menu-header {
  background-color: $clr-row-hover-color;
}

//Left Panel
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR {
  .panel-inner {
    .object-navigator {
      .nav.nav-tabs {
        border-bottom: 1px solid $clr-border-color;
      }
    }
  }
}

//Tabs
.vui-wizard,
.vui-panel,
.modal-dialog {
  .vx-tab-wrapper,
  .wizard-modal-content,
  [vx-navi-tab-headers],
  .modal-content {
    &[level=first],
    &[level=details] {
      .nav.nav-tabs {
        border-bottom: 1px solid $clr-border-color;
      }
    }

    clr-wizard-page {
      .vui-tabs-container {
        .vui-secondary-tabs {
          .btn-group {
            background-color: transparent;
            .btn.active {
              @extend %clr-button-active-style;
            }
          }
          .btn-group>.btn {
            @extend %clr-button-style;
          }
        }
      }
    }
  }
}

//Bar charts
.horizontal-resource-meter .meter-gauge {
  background-color:$clr-bar-chart-background-color;
  .meter-gauge-value {
    background-color: $clr-bar-chart-fill-color;
  }
}

//Shortcuts page header
.controlcenter .controlcenter-category {
  color: $vui-header-color;
  font-weight: 400;
  border-bottom: 1px solid $clr-border-color;
}

//Administration -> Roles -> Privileges List
.roles-view {
  .privileges-container {
    .privilege-category-group {
      label.privileges-category-label {
        color: $vui-text-color;
        font-size: 13px;
        font-weight: 600;
      }
      ul.list li {
        color: $vui-text-color;
      }
    }
  }
}


//Recent tasks panel
#bottom-panel-pane {
  box-shadow: 0 -2px 3px 3px hsla(0, 0%, 0%, 0.20);
}

//Connection timeout alert
.vx-alert-modal, .vx-modal {
  background: $clr-table-cell-background-color;
}

.vx-modal-titlebar {
  background: $clr-table-header-background-color;
  color: $vui-text-color;
}

//Radio button groups
.radio.btn input[type=radio]:checked+label {
  color: $clr-badge-text-color;
}

//Text box
.form-control {
  background-color: $vui-background-color;
  color: $vui-text-color;
}

// VM Summary Page Summary Meter Labels
.summary {
  .summary-meters-block {
    .summary-items-list {
      .resource-meter {
        .title, .resource-meter-top-right-info,
        .resource-meter-bottom-left-info, .resource-meter-bottom-right-info {
          color: $vui-header-color;
        }
      }
    }
  }
}

// Developer Center
vmw-accordion {
  .accordion-body {
    .response {
      pre.ng-star-inserted {
        background-color: $vui-background-color;
        color: $vui-text-color;
      }
    }
  }
}