%svg-common {
	background: url("img/svg-sprite-dark.svg") no-repeat;
}

.vsphere-icon-Add_Host {
	@extend %svg-common;
	background-position: 44.827586206896555% 60.42402826855124%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Compute_policy {
	@extend %svg-common;
	background-position: 87.48275862068965% 12.67605633802817%;
	width: 18px;
	height: 17px;
}

.vsphere-icon-Host_Connect {
	@extend %svg-common;
	background-position: 81.27586206896552% 31.802120141342755%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Host_Disconnect {
	@extend %svg-common;
	background-position: 6.206896551724138% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Host_Maintenance_Mode_Enter {
	@extend %svg-common;
	background-position: 12.413793103448276% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Host_Maintenance_Mode_Exit {
	@extend %svg-common;
	background-position: 24.82758620689655% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Host_Policy {
	@extend %svg-common;
	background-position: 43.44827586206897% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Host_Reboot {
	@extend %svg-common;
	background-position: 75.06896551724138% 0;
	width: 18px;
	height: 18px;
}

.vsphere-icon-LAG {
	@extend %svg-common;
	background-position: 75.06896551724138% 31.802120141342755%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Lic_key_add {
	@extend %svg-common;
	background-position: 66.55555555555556% 28.897338403041825%;
	width: 38px;
	height: 38px;
}

.vsphere-icon-Lic_key_existing {
	@extend %svg-common;
	background-position: 37.2962962962963% 46.00760456273764%;
	width: 38px;
	height: 38px;
}

.vsphere-icon-Lic_key_inactive {
	@extend %svg-common;
	background-position: 51.37037037037037% 46.00760456273764%;
	width: 38px;
	height: 38px;
}

.vsphere-icon-Lic_key_remove {
	@extend %svg-common;
	background-position: 66.55555555555556% 0;
	width: 38px;
	height: 38px;
}

.vsphere-icon-Lic_key_upgraded {
	@extend %svg-common;
	background-position: 66.55555555555556% 14.448669201520913%;
	width: 38px;
	height: 38px;
}

.vsphere-icon-NIC_blue {
	@extend %svg-common;
	background-position: 37.241379310344826% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-NIC_single {
	@extend %svg-common;
	background-position: 87.48275862068965% 50.53003533568904%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-NICs {
	@extend %svg-common;
	background-position: 93.6896551724138% 50.88339222614841%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-NiC_green {
	@extend %svg-common;
	background-position: 51.03448275862069% 60.42402826855124%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-action-remove {
	@extend %svg-common;
	background-position: 57.241379310344826% 60.42402826855124%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-add {
	@extend %svg-common;
	background-position: 63.44827586206897% 60.42402826855124%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-assign_license {
	@extend %svg-common;
	background-position: 0 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-caret_collapsed {
	@extend %svg-common;
	background-position: 6.206896551724138% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-caret_expanded {
	@extend %svg-common;
	background-position: 12.413793103448276% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cloud-network {
	@extend %svg-common;
	background-position: 18.620689655172413% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cloud-network-alert {
	@extend %svg-common;
	background-position: 24.82758620689655% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cloud-network-warning {
	@extend %svg-common;
	background-position: 31.03448275862069% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cluster {
	@extend %svg-common;
	background-position: 37.241379310344826% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cluster-24x {
	@extend %svg-common;
	background-position: 9.21985815602837% 62.18181818181818%;
	width: 26px;
	height: 26px;
}

.vsphere-icon-cluster-error {
	@extend %svg-common;
	background-position: 49.6551724137931% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cluster-warning {
	@extend %svg-common;
	background-position: 55.86206896551724% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-collapse {
	@extend %svg-common;
	background-position: 68.79598662207358% 39.04109589041096%;
	width: 9px;
	height: 9px;
}

.vsphere-icon-content_library {
	@extend %svg-common;
	background-position: 68.27586206896552% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cpu-summary {
	@extend %svg-common;
	background-position: 63.723404255319146% 50.90909090909091%;
	width: 26px;
	height: 26px;
}

.vsphere-icon-datacenter {
	@extend %svg-common;
	background-position: 75.06896551724138% 6.360424028268551%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datacenter-error {
	@extend %svg-common;
	background-position: 75.06896551724138% 12.720848056537102%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datacenter-warning {
	@extend %svg-common;
	background-position: 75.06896551724138% 19.081272084805654%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore {
	@extend %svg-common;
	background-position: 75.06896551724138% 25.441696113074205%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-24x {
	@extend %svg-common;
	background-position: 63.723404255319146% 41.45454545454545%;
	width: 26px;
	height: 26px;
}

.vsphere-icon-datastore-cluster {
	@extend %svg-common;
	background-position: 75.06896551724138% 38.16254416961131%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-cluster-error {
	@extend %svg-common;
	background-position: 75.06896551724138% 44.522968197879855%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-cluster-warning {
	@extend %svg-common;
	background-position: 75.06896551724138% 50.88339222614841%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-error {
	@extend %svg-common;
	background-position: 75.06896551724138% 57.24381625441696%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-inaccessible {
	@extend %svg-common;
	background-position: 75.06896551724138% 63.60424028268551%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-maintenance {
	@extend %svg-common;
	background-position: 0 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-warning {
	@extend %svg-common;
	background-position: 6.206896551724138% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-dell {
	@extend %svg-common;
	background-position: 46.86046511627907% 28.286852589641434%;
	width: 50px;
	height: 50px;
}

.vsphere-icon-devcenter {
	@extend %svg-common;
	background-position: 18.620689655172413% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-drag-and-drop-accept {
	@extend %svg-common;
	background-position: 24.82758620689655% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-drag-and-drop-reject {
	@extend %svg-common;
	background-position: 31.03448275862069% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-dv-switch {
	@extend %svg-common;
	background-position: 37.241379310344826% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-dv-switch-alert {
	@extend %svg-common;
	background-position: 43.44827586206897% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-dv-switch-warning {
	@extend %svg-common;
	background-position: 49.6551724137931% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-dvsPnicError {
	@extend %svg-common;
	background-position: 23.80952380952381% 100%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-event-console {
	@extend %svg-common;
	background-position: 62.06896551724138% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-export-line_16x16 {
	@extend %svg-common;
	background-position: 68.27586206896552% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-filebrowser-copy {
	@extend %svg-common;
	background-position: 74.48275862068965% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-filebrowser-download {
	@extend %svg-common;
	background-position: 81.27586206896552% 0;
	width: 18px;
	height: 18px;
}

.vsphere-icon-filebrowser-move {
	@extend %svg-common;
	background-position: 81.27586206896552% 6.360424028268551%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-filebrowser-upload {
	@extend %svg-common;
	background-position: 81.27586206896552% 12.720848056537102%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-folder {
	@extend %svg-common;
	background-position: 81.27586206896552% 19.081272084805654%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-folder-add {
	@extend %svg-common;
	background-position: 81.27586206896552% 25.441696113074205%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-fujitsu {
	@extend %svg-common;
	background-position: 0 48.20717131474104%;
	width: 100.7px;
	height: 50px;
}

.vsphere-icon-help-info {
	@extend %svg-common;
	background-position: 81.27586206896552% 38.16254416961131%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-help-info-hover {
	@extend %svg-common;
	background-position: 81.27586206896552% 44.522968197879855%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-host {
	@extend %svg-common;
	background-position: 81.27586206896552% 50.88339222614841%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-host-disconnected {
	@extend %svg-common;
	background-position: 81.27586206896552% 57.24381625441696%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-host-error {
	@extend %svg-common;
	background-position: 81.27586206896552% 63.60424028268551%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-host-maintenance {
	@extend %svg-common;
	background-position: 81.27586206896552% 69.96466431095406%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-host-warning {
	@extend %svg-common;
	background-position: 0 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-hp {
	@extend %svg-common;
	background-position: 0 0;
	width: 179.7px;
	height: 71px;
}

.vsphere-icon-ibm {
	@extend %svg-common;
	background-position: 0 28.286852589641434%;
	width: 120.9px;
	height: 50px;
}

.vsphere-icon-info {
	@extend %svg-common;
	background-position: 18.620689655172413% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-memory-summary {
	@extend %svg-common;
	background-position: 18.43971631205674% 62.18181818181818%;
	width: 26px;
	height: 26px;
}

.vsphere-icon-network {
	@extend %svg-common;
	background-position: 31.03448275862069% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-network-24x {
	@extend %svg-common;
	background-position: 27.659574468085108% 62.18181818181818%;
	width: 26px;
	height: 26px;
}

.vsphere-icon-network-error {
	@extend %svg-common;
	background-position: 43.44827586206897% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-network-warning {
	@extend %svg-common;
	background-position: 49.6551724137931% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-ovf-deploy {
	@extend %svg-common;
	background-position: 55.86206896551724% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-plugin {
	@extend %svg-common;
	background-position: 62.06896551724138% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-portChannel {
	@extend %svg-common;
	background-position: 68.27586206896552% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-port_blocked {
	@extend %svg-common;
	background-position: 19.047619047619047% 100%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_blocked_SRIOV {
	@extend %svg-common;
	background-position: 80.6896551724138% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-port_blocked_SRIOV12x {
	@extend %svg-common;
	background-position: 14.285714285714286% 100%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_blocked_left {
	@extend %svg-common;
	background-position: 9.523809523809524% 100%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_blue-grey {
	@extend %svg-common;
	background-position: 28.571428571428573% 100%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_blue-grey_left {
	@extend %svg-common;
	background-position: 98.5374149659864% 87.8048780487805%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_count {
	@extend %svg-common;
	background-position: 87.48275862068965% 25.08833922261484%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-port_green {
	@extend %svg-common;
	background-position: 0 100%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_green_SRIOV {
	@extend %svg-common;
	background-position: 87.48275862068965% 37.80918727915194%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-port_green_SRIOV12x {
	@extend %svg-common;
	background-position: 68.70748299319727% 59.58188153310105%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_green_left {
	@extend %svg-common;
	background-position: 74.04761904761905% 68.98954703832753%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_red_X {
	@extend %svg-common;
	background-position: 80.17006802721089% 75.26132404181185%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_red_X_left {
	@extend %svg-common;
	background-position: 92.41496598639456% 87.8048780487805%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_white {
	@extend %svg-common;
	background-position: 4.761904761904762% 100%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_white_left {
	@extend %svg-common;
	background-position: 98.5374149659864% 92.6829268292683%;
	width: 14px;
	height: 14px;
}

.vsphere-icon-portgroup {
	@extend %svg-common;
	background-position: 87.48275862068965% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-powered-on {
	@extend %svg-common;
	background-position: 0 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-precheck-error {
	@extend %svg-common;
	background-position: 6.206896551724138% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-precheck-ok {
	@extend %svg-common;
	background-position: 12.413793103448276% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-precheck-warning {
	@extend %svg-common;
	background-position: 18.620689655172413% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-remove {
	@extend %svg-common;
	background-position: 24.82758620689655% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-rescan-hba {
	@extend %svg-common;
	background-position: 31.03448275862069% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-rescan-storage {
	@extend %svg-common;
	background-position: 37.241379310344826% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-resource-pool {
	@extend %svg-common;
	background-position: 43.44827586206897% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-roles-clone,
.vsphere-icon-roles-clone\:regular {
	@extend %svg-common;
	background-position: 49.6551724137931% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-roles-clone:hover,
.vsphere-icon-roles-clone\:hover {
	@extend %svg-common;
	background-position: 55.86206896551724% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-rules-profile {
	@extend %svg-common;
	background-position: 62.06896551724138% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-scsi-adapter {
	@extend %svg-common;
	background-position: 68.27586206896552% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-scsi-adapter-warning {
	@extend %svg-common;
	background-position: 74.48275862068965% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-snapshot {
	@extend %svg-common;
	background-position: 80.6896551724138% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-snapshot_here {
	@extend %svg-common;
	background-position: 86.89655172413794% 88.69257950530036%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-snapshot_run {
	@extend %svg-common;
	background-position: 93.6896551724138% 0;
	width: 18px;
	height: 18px;
}

.vsphere-icon-snapshot_suspend {
	@extend %svg-common;
	background-position: 93.6896551724138% 6.360424028268551%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-status-error {
	@extend %svg-common;
	background-position: 93.6896551724138% 12.720848056537102%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-status-ok {
	@extend %svg-common;
	background-position: 93.6896551724138% 19.081272084805654%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-status-unknown {
	@extend %svg-common;
	background-position: 93.6896551724138% 25.441696113074205%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-status-warning {
	@extend %svg-common;
	background-position: 93.6896551724138% 31.802120141342755%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-storage-device-mount {
	@extend %svg-common;
	background-position: 93.6896551724138% 38.16254416961131%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-storage-device-unmount {
	@extend %svg-common;
	background-position: 93.6896551724138% 44.522968197879855%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-storage-summary {
	@extend %svg-common;
	background-position: 0 62.18181818181818%;
	width: 26px;
	height: 26px;
}

.vsphere-icon-storage-system-refresh {
	@extend %svg-common;
	background-position: 93.6896551724138% 57.24381625441696%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-suspended {
	@extend %svg-common;
	background-position: 93.6896551724138% 63.60424028268551%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-synchronize {
	@extend %svg-common;
	background-position: 93.6896551724138% 69.96466431095406%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-task-console {
	@extend %svg-common;
	background-position: 93.6896551724138% 76.32508833922262%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-add,
.vsphere-icon-toolbar-add\:regular {
	@extend %svg-common;
	background-position: 93.6896551724138% 82.68551236749117%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-add-folder {
	@extend %svg-common;
	background-position: 0 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-add-vm {
	@extend %svg-common;
	background-position: 6.206896551724138% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-add:hover,
.vsphere-icon-toolbar-add\:hover {
	@extend %svg-common;
	background-position: 12.413793103448276% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-edit {
	@extend %svg-common;
	background-position: 18.620689655172413% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-host-question {
	@extend %svg-common;
	background-position: 24.82758620689655% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-more {
	@extend %svg-common;
	background-position: 31.03448275862069% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-network-add {
	@extend %svg-common;
	background-position: 37.241379310344826% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-remove {
	@extend %svg-common;
	background-position: 43.44827586206897% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-roles {
	@extend %svg-common;
	background-position: 49.6551724137931% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-uplink-port-group {
	@extend %svg-common;
	background-position: 55.86206896551724% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-uplink-port-group-alert {
	@extend %svg-common;
	background-position: 62.06896551724138% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-uplink-port-group-warning {
	@extend %svg-common;
	background-position: 68.27586206896552% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp {
	@extend %svg-common;
	background-position: 74.48275862068965% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-add {
	@extend %svg-common;
	background-position: 80.6896551724138% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-edit {
	@extend %svg-common;
	background-position: 86.89655172413794% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-managed {
	@extend %svg-common;
	background-position: 93.10344827586206% 95.0530035335689%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-running {
	@extend %svg-common;
	background-position: 99.89655172413794% 0;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-running-managed {
	@extend %svg-common;
	background-position: 99.89655172413794% 6.360424028268551%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-suspended {
	@extend %svg-common;
	background-position: 99.89655172413794% 12.720848056537102%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-suspended-managed {
	@extend %svg-common;
	background-position: 99.89655172413794% 19.081272084805654%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vc-extension {
	@extend %svg-common;
	background-position: 99.89655172413794% 25.441696113074205%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vcenter {
	@extend %svg-common;
	background-position: 99.89655172413794% 31.802120141342755%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-view_properties {
	@extend %svg-common;
	background-position: 99.89655172413794% 38.16254416961131%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-virtual-port-group {
	@extend %svg-common;
	background-position: 99.89655172413794% 44.522968197879855%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-virtual-port-group-alert {
	@extend %svg-common;
	background-position: 99.89655172413794% 50.88339222614841%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-virtual-port-group-warning {
	@extend %svg-common;
	background-position: 99.89655172413794% 57.24381625441696%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm {
	@extend %svg-common;
	background-position: 99.89655172413794% 63.60424028268551%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-add {
	@extend %svg-common;
	background-position: 99.89655172413794% 69.96466431095406%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-add-from-template {
	@extend %svg-common;
	background-position: 99.89655172413794% 76.32508833922262%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-error {
	@extend %svg-common;
	background-position: 99.89655172413794% 82.68551236749117%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-information {
	@extend %svg-common;
	background-position: 87.48275862068965% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-on {
	@extend %svg-common;
	background-position: 87.48275862068965% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-suspended {
	@extend %svg-common;
	background-position: 87.48275862068965% 63.25088339222615%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-template {
	@extend %svg-common;
	background-position: 87.48275862068965% 56.890459363957596%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-templates-24x {
	@extend %svg-common;
	background-position: 36.87943262411348% 62.18181818181818%;
	width: 26px;
	height: 26px;
}

.vsphere-icon-vm-warning {
	@extend %svg-common;
	background-position: 87.48275862068965% 44.169611307420496%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmManaged {
	@extend %svg-common;
	background-position: 87.48275862068965% 31.448763250883392%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmOnManaged {
	@extend %svg-common;
	background-position: 87.48275862068965% 18.72791519434629%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmRedManaged {
	@extend %svg-common;
	background-position: 87.48275862068965% 6.360424028268551%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmStuckManaged {
	@extend %svg-common;
	background-position: 87.48275862068965% 0;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmSuspendedManaged {
	@extend %svg-common;
	background-position: 74.48275862068965% 82.3321554770318%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmYellowManaged {
	@extend %svg-common;
	background-position: 55.86206896551724% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vsphere-admin {
	@extend %svg-common;
	background-position: 62.06896551724138% 69.6113074204947%;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vsphere-inventory-lists {
	@extend %svg-common;
	background-position: 12.413793103448276% 75.97173144876325%;
	width: 18px;
	height: 18px;
}

