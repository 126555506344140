@charset "utf-8";

//=============================================================================
// OLD Clarity Preview code, slowly delete these please

// Clarity color palette, clarity/utils/colors.scss

$clr-white: #fff;
$clr-almost-white: #fafafa;
$clr-near-white: #eee;
$clr-light-gray: #ddd;
$clr-light-midtone-gray: #bbb;
$clr-dark-midtone-gray: #9a9a9a;
$clr-gray: #565656;
$clr-dark-gray: $clr-gray;
$clr-near-black: #313131;

$clr-actionblue: #0079b8;

$clr-yellow: #efd603;
$clr-yellow-list: (#f9f0e1, #eed7a9, $clr-yellow, #eb8d00, #ce5c00, #c25400, #9e4100, #642100);

// Clarity 0.31 Overrides

a:active, a:visited {
  color: #0275d8;
}

//== Clarity Theme Color Palette
// ----------------------------------------------------------------------------

$light-grey-10: $clr-near-white;
$light-grey-20: $clr-light-gray;
$light-grey-30: $clr-light-gray;
$light-grey-40: #c7d1d6;
$light-grey-50: $clr-light-midtone-gray;

$medium-grey: $clr-dark-midtone-gray;
$medium-grey-10: #747474;
$medium-grey-20: $clr-gray;

$dark-grey: $clr-dark-gray;
$dark-grey-10: $clr-near-black;

$light-orange: nth($clr-yellow-list, 2);
$dark-orange: nth($clr-yellow-list, 5);
